<template>
  <div>
    <div class="relative">
      <button
        class="relative z-10 flex select-none items-center focus:outline-none"
        @click="open = !open"
      >
        <slot name="trigger"></slot>
      </button>

      <button
        v-if="open"
        class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
        tabindex="-1"
        @click="open = false"
      ></button>
      <!--dropdown content -->
      <transition
        enter-active-class="transition-all duration-100 ease-out"
        leave-active-class="transition-all duration-100 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="absolute mt-4 block rounded border bg-white px-2 py-1 text-sm shadow-lg"
          :class="placementClass"
        >
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

type Props = {
  placement: "top" | "bottom" | "left" | "right";
  arrow?: boolean;
};
const props = defineProps<Props>();
const open = ref(false);
const placementClass = computed(() => {
  const { placement } = props;
  if (placement === "bottom") {
    return `top-[100%]`;
  }
  if (placement === "top") {
    return `bottom-[100%] mb-[10px]`;
  }
  if (placement === "left") {
    return `right-[100%]`;
  }
  if (placement === "right") {
    return `left-[100%]`;
  }
  return `top-[100%]`;
});

function onCloseDropdown() {
  open.value = false;
}

defineOptions({
  name: "HhDropdown",
});

defineExpose({
  onCloseDropdown,
});
</script>
